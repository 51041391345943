/**
 * FOCUS TRAPPER
 *
 * Loop focus in a container (in a modal for example).
 */
var FocusTrapper = (function() {

  // Constructor
  function FocusTrapper($container, settings) {
    var _ = this;

    _.settings = $.extend(true, {
      selector: 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])', // https://gomakethings.com/how-to-get-the-first-and-last-focusable-elements-in-the-dom/
    }, settings || {});

    _.$container = $container;
    _.$firstFocusable = null;
    _.$lastFocusable = null;
    _.state = 'resting';        // Global status, catching : have trapped focus, resting : focus is free

    // We ensure that the target container can be focus
    if (typeof _.$container.attr('tabindex') === 'undefined') {
      _.$container.attr('tabindex', '-1');
    }
  }

  // Methods

  // Update observables elements
  FocusTrapper.prototype.update = function() {
    var _ = this;

    var $focusables = _.$container.find(_.settings.selector).filter(':visible');

    _.$firstFocusable = $focusables.eq(0);
    _.$lastFocusable = $focusables.eq($focusables.length - 1);
  };

  // Activate trapping
  FocusTrapper.prototype.catch = function() {
    var _ = this;

    _.update();

    _.$container.focus();
    _.$container.on('keydown', $.proxy(_.handleKeydown, _));

    _.state = 'catching';
  };

  // Deactivate trapping
  FocusTrapper.prototype.release = function() {
    var _ = this;

    _.$container.off('keydown', $.proxy(_.handleKeydown, _));

    _.state = 'resting';
  };

  // Detect if we need to loop forward or back
  FocusTrapper.prototype.handleKeydown = function(e) {
    var _ = this;

    if (e.which === 9) {

      if (e.shiftKey && (e.target === _.$firstFocusable.get(0) || e.target === _.$container.get(0))) {
        e.preventDefault();
        _.$lastFocusable.focus();
      }

      if (!e.shiftKey && e.target === _.$lastFocusable.get(0)) {
        e.preventDefault();
        _.$firstFocusable.focus();
      }

    }
  }

  return FocusTrapper;

})();
