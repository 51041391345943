var build_threshold = function (num_steps) {
  var thresholds = [];

  for (var i=1.0; i<=num_steps; i++) {
    var ratio = i/num_steps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
};
